/*************************
      Breadcrumbs
*************************/
.breadcrumb-light {
	.breadcrumb-item {

		i{
			color: $white;
		}

		a {
			color: $white;
		}

		+ {
			.breadcrumb-item {
				&::before {
					color: $white;
				}

			}

		}

	}

	.breadcrumb-item.active {
		color: $white;
	}

}