/*************************
        Portfolio
*************************/

.portfolio-item {
  position: relative;
  overflow: hidden;

  &:hover {
    .portfolio-overlay {
      background: rgba($white, .80);
      opacity: 1;
    }

    .portfolio-info {
      bottom: 20px;
    }

    .portfolio-icon {
      top: 20px;
    }

  }

}

.portfolio-overlay {
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  opacity: 0;
  height: 100%;
  align-items: flex-end;
  transition: all 0.5s ease;
  color: $white;

  .portfolio-title {
    color: $primary;
  }

}

.portfolio-info {
  position: absolute;
  bottom: 0px;
  transition: all 0.5s ease;
  width: 100%;
  left: 20px;
}

.portfolio-icon {
  position: absolute;
  top: 0px;
  left: 20px;
  transition: all 0.5s ease;
  align-self: center;

  span {
    color: $dark;
    font-size: 18px;
  }

  i {
    color: $primary;
    font-size: 20px;
  }

}

.portfolio-title {
  color: $white;
  margin-bottom: 0;
}


/*************************************
         isotope
 *************************************/
.isotope-filters {
  display: table;
  margin: 0 auto 50px;
  text-align: center;

  button {
    margin: 0 10px 10px;
    padding: 14px 30px;
    cursor: pointer;
    font-size: 14px;
    background: transparent;
    border: none;
    transition: all 0.3s ease;
    border: 1px solid rgba($black,.05);
    font-weight: 500;
    line-height: 18px;
    border-radius: 3px;

    &:hover {
      color: $white;
      border: 1px solid $primary;
      background: $primary;
    }

  }

  button.active {
    color: $white;
    border: 1px solid $primary;
    background: $primary;
  }

}

.isotope-filters.text-left {
  display: block;
  margin: 30px 0;
  text-align: left;
}

.isotope {
  margin-left: -15px;
  margin-right: -15px;

  .grid-item {
    img {
      width: 100%;
    }

  }

}

.isotope.columns-1 {
  .grid-item {
    width: 100%;
    padding: 15px;
  }

}

.isotope.columns-2 {
  .grid-item {
    width: 50%;
    padding: 15px;
  }

}

.isotope.columns-3 {
  .grid-item {
    width: 33.33333333%;
    padding: 15px;
  }

}

.isotope.columns-4 {
  .grid-item {
    width: 25%;
    padding: 15px;
  }

}

.isotope.columns-5 {
  .grid-item {
    width: 20%;
    padding: 15px;
  }

}

@include media-breakpoint-down(sm) {
  .isotope.columns-3 {
    .grid-item {
      width: 50%;
    }

  }

  .isotope.columns-4 {
    .grid-item {
      width: 50%;
    }

  }

  .isotope.columns-5 {
    .grid-item {
      width: 50%;
    }

  }

}

@include media-breakpoint-down(xs) {
  .isotope.columns-2 {
    .grid-item {
      width: 100%;
    }

  }

  .isotope.columns-3 {
    .grid-item {
      width: 100%;
    }

  }

  .isotope.columns-4 {
    .grid-item {
      width: 100%;
    }

  }

  .isotope.columns-5 {
    .grid-item {
      width: 100%;
    }

  }

  .isotope-filters {
    button {
      font-size: 13px;
      margin: 0px 10px 10px;
    }

  }

}