/*************************
        Helper
*************************/

body {
  overflow-x: hidden;
}

a {
  transition: all 0.3s ease-in-out;
}

a:focus, button:focus {
  outline: none;
}

// img holder
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

// Background overlay
.bg-overlay-black-10 {
  &:before {
    background: rgba($black, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-black-20 {
  &:before {
    background: rgba($black, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-black-30 {
  &:before {
    background: rgba($black, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-black-40 {
  &:before {
    background: rgba($black, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-black-50 {
  &:before {
    background: rgba($black, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-black-60 {
  &:before {
    background: rgba($black, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-black-70 {
  &:before {
    background: rgba($black, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-black-80 {
  &:before {
    background: rgba($black, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-black-90 {
  &:before {
    background: rgba($black, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
   z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-10 {
  &:before {
    background: rgba($white, 0.1);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-20 {
  &:before {
    background: rgba($white, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-30 {
  &:before {
    background: rgba($white, 0.3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-40 {
  &:before {
    background: rgba($white, 0.4);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-50 {
  &:before {
    background: rgba($white, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-60 {
  &:before {
    background: rgba($white, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-70 {
  &:before {
    background: rgba($white, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-80 {
  &:before {
    background: rgba($white, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-white-90 {
  &:before {
    background: rgba($white, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-theme-20 {
  &:before {
    background: rgba($primary, 0.2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-theme-50 {
  &:before {
    background: rgba($primary, 0.5);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-theme-60 {
  &:before {
    background: rgba($primary, 0.6);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-theme-70 {
  &:before {
    background: rgba($primary, 0.7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-theme-80 {
  &:before {
    background: rgba($primary, 0.8);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  position: relative;
  z-index: 1;
}

.bg-overlay-theme-90 {
  &:before {
    background: rgba($primary, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
  position: relative;
  z-index: 1;
}

/*** rotate ***/
.rotate-180 {
  transform: rotate(180deg);
}

.rotate-n90 {
  transform: rotate(-90deg);
}

/*** positions ***/
.position-all-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.top {
  top: 0;
}

.bottom {
  bottom: 0;
}

/*** Z index ***/

.z-index-n1 {
  z-index: -1;
}

.z-index-0 {
  z-index: 0;
}

.z-index-9 {
  z-index: 9;
}

.z-index-99 {
  z-index: 99;
}

// fill colors for svg

.fill-dark { fill: $gray-900; }

.fill-primary { fill: $primary; }

.fill-light { fill: $gray-200; }

.fill-white { fill: $white; }

.fill-light-pink { fill: #ffb8b8; }

.fill-dark-pink { fill: #e5a5a5; }

.fill-blue { fill: #03345f; }

.logo-text {
  font-size: 500px;
  font-weight: 500;
}

.fill-primary-1{fill: $primary; opacity:0.1;}


// grayscale
.grayscale {
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
  &:hover {
    filter: inherit;
  }
}

.border-radius {
  border-radius: $border-radius;
}

  .border-radius-sm {
  border-radius: $border-radius-sm;
}

  .border-radius-lg {
  border-radius: $border-radius-lg;
}


.img-shadow-50 {
  position: relative;

    &:before {
    content: "";
    position: absolute;
    right: -15px;
    bottom: -15px;
    width: 50%;
    height: 50%;
    background: $primary;
  }

}


.bg-white-right-half-30 {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    height: 100%;
    background: $white;
  }
}

.bg-white-right-half-40 {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 40%;
    height: 100%;
    background: $white;
  }
}

/*** blockquote ***/
.blockquote.quote {
  position: relative;
  color: $gray-900;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 10px;
  margin-bottom: 0;

  &:before {
    content: "\f110";
    font-family: Flaticon;
    font-size: 75px;
    font-weight: bold;
    position: absolute;
    left: 0px;
    top: -50px;
    color: rgba($black, .10);
    font-weight: 300;
  }

}

.icon-sm {
  width: 30px;
  height: 30px;
  line-height: 40px;
  text-align: center;
}

.icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.icon-lg {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 1.2rem;
}

.icon-xl {
  width: 90px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  font-size: 1.3rem;
}

.object-cover{
  object-fit: cover;
}

#back-to-top .arrow {
    box-shadow: 0 3px 10px rgba($black, .3);
    margin: 0px;
    color: $white;
    transition: all .5s ease-in-out;
    position: fixed;
    bottom: 40px;
    right: 30px;
    border-radius: $border-radius;
    z-index: 999;
    background: transparent;
    font-size: 14px;
    background: $primary;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 45px;
    &:hover {
      background: $gray-900;
    }
}


/***comming soon css***/
.facebook-bg {
  background-color: #445c8e; }

.twitter-bg {
  background-color: #43afe9; }

.google-bg {
  background-color: #dc0510; }

.linkedin-bg {
  background-color: #13799f; }

.social-bg-hover {
  position: relative;
  color: $white;
}

.social-bg-hover span {
  position: relative;
  color: $white;
}

.social-bg-hover:before {
  content: "";
  color: $white;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.social-bg-hover:hover {
  color: $white;
}

.social-bg-hover:hover:before {
  background-color: rgba(0, 0, 0, 0.1);
}

/*** index-06 ***/
.banner-right-img{
  right: 170px;
}

.mobile-app{
  position: relative;
  .owl-dots{
   position: absolute;
   width: 100%;
   bottom: -80px;
  }
}


@media (max-width:1199px) {

  .testimonial .items .bottom{
    bottom: -5px;
  }

  .mobile-app{
  .owl-dots{
   bottom: -100px;
  }
}

}

@media (max-width:991px) {

  .bg-white-right-half-40 {
    position: relative;

    &:before {
      width: 0;
    }
  }

  .about-shape {
    .bottom{
      bottom: -5px;
    }
  }

  .img-fluid-md {
    width: 100%;
  }

  .bg-white-right-half-30 {
     &:before {
      background: none;
     }
   }

.banner-right-img{
  right: 0px;
}

  .mobile-app{
  .owl-dots{
   bottom: -50px;
  }
}

}


/*** SVG IE11 specific styles ***/

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src$=".svg"] {
    width: 100%;
    }

  .ie-h-28 {
    height: 28px !important;
  }
  .ie-h-34 {
    height: 34px !important;
  }
  .ie-h-40 {
    height: 40px !important;
  }
  .ie-h-50 {
    height: 50px !important;
  }
  .ie-h-65 {
    height: 65px !important;
  }
  .ie-h-70 {
    height: 70px !important;
  }
  .ie-h-154 {
    height: 154px !important;
  }
  .ie-h-160 {
    height: 160px !important;
  }
  .ie-h-172 {
    height: 172px !important;
  }
  .ie-h-186 {
    height: 186px !important;
  }
  .ie-h-192 {
    height: 192px !important;
  }
  .ie-h-206 {
    height: 206px !important;
  }
  .ie-h-208 {
    height: 208px !important;
  }
  .ie-h-225 {
    height: 225px !important;
  }
  .ie-h-242 {
    height: 242px !important;
  }
  .ie-h-248 {
    height: 248px !important;
  }
  .ie-h-265 {
    height: 265px !important;
  }
  .ie-h-266 {
    height: 266px !important;
  }
  .ie-h-320 {
    height: 320px !important;
    width: 846px !important;
  }
  .ie-h-376 {
    height: 376px !important;
  }
  .ie-h-450 {
    height: 450px !important;
  }
  .ie-h-480 {
    height: 480px !important;
  }
  .ie-h-520 {
    height: 520px !important;
  }
  .ie-h-550 {
    height: 550px !important;
  }
  .ie-h-570 {
    height: 570px !important;
  }
  .ie-h-680 {
    height: 680px !important;
  }
  .ie-h-700 {
    height: 700px !important;
  }
  .card img{
    height: 100%;
  }
  .card img.card-img-top {
    height: 220px;
  }
  .card img.img-fluid.card-img-top {
    height: 100%;
  }

}
