/*************************
      Avatar
*************************/

.avatar {
    position: relative;
    display: inline-block;
    object-fit: cover;
    width: auto;
    height: auto;
    font-size: 1rem;
}

.avatar-xl {
    width: 6rem;
    height: 6rem;
    min-width: 6rem;
    min-height: 6rem;
}

.avatar-lg {
    width: 5rem;
    height: 5rem;
    min-width: 5rem;
    min-height: 5rem;
}

.avatar-md {
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
}

.avatar-sm {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
}
