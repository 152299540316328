/*** intro css ***/
.intro {
    padding-top: 170px;
    padding-bottom: 100px;
    height: 100vh;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.intro-content {
    position: relative;
    z-index: 9;
    text-align: center;
}

.intro-content h1{
    font-weight: 500;
    margin-bottom: 15px;
}

.intro-content a{
    margin-top: 30px;
}

.header.intro-header.is-sticky  .navbar{
    position: relative;
    top: 0;
    left: 0;
}

.portfolio {

    .thumb {
        position: relative;
        margin-bottom: 20px;
        box-shadow: $box-shadow;
        transition: all .3s cubic-bezier(.65, .05, .36, 1);
    }

    .pv-port-info {
        h5 {
            a {
                color: $black;
                font-size: 18px;
                line-height: 1.39;

                &:hover {
                    color: $primary;
                }

            }

        }

    }

    &:hover {
        .thumb {
            transform: translateY(-10px);
        }

    }

}


@media (max-width:991px) {
 .header{
    position: relative;
    top: 0;
    background:$light;
 }

 .intro {
    padding-top: 80px;
}

}

@media (max-width:575px) {

.intro {
    padding-top: 40px;
}

.intro-content a {
    margin-top: 0px;
}

}